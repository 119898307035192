import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../config/aws";
import _ from "lodash";
import {
  updateProduct,
  getSingleProduct,
  getCategories,
  getProductSubCategories,
  getProductSubSubCategories,
  addFilter,
} from "../../../redux/reducers/seller";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { ToastContainer, toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Row,
  Col,
  Table,
  Button,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "./../product.css";
import SkyLight from "react-skylight";
import "./editproduct.css";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../helpers/s3Utils";

const myBigGreenDialog = {
  width: "40%",
  minHeight: "20%",
};

const AddProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const addProductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const otherProductTranslation =
    translationState?.[0]?.translations?.otherProduct?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);
  const fileInput = useRef(null);
  const attributesModal = useRef(null);
  const variantsModal = useRef(null);
  const tierPriceModal = useRef(null);
  const materialModal = useRef(null);
  const dimension_units = [
    `${
      addProductTranslation?.centimeter
        ? addProductTranslation?.centimeter
        : "centimeter"
    }`,
    `${
      addProductTranslation?.inches ? addProductTranslation?.inches : "inches"
    }`,
    `${addProductTranslation?.feet ? addProductTranslation?.feet : "feet"}`,
    `${addProductTranslation?.meter ? addProductTranslation?.meter : "meter"}`,
  ];
  const weight_units = [
    `${addProductTranslation?.kilo ? addProductTranslation?.kilo : "kilogram"}`,
    `${addProductTranslation?.pound ? addProductTranslation?.pound : "pound"}`,
  ];
  const {
    productCategories,
    productSubCategories,
    productSubSubCategories,
    singleProduct,
    loading,
  } = useSelector((state) => state.seller);
  const [pageContent, setPageContent] = useState({
    productAttributes: [],
    productMaterial: [],
    priceTier: [],
    images: [],
    dimensionUnit: "centimeter",
    weightUnit: "kilogram",
  });
  const [productDescription, setProductDescription] = useState({
    editorState: EditorState.createEmpty(),
  });
  const [productDescriptionData, setProductDescriptionData] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const [formError, setFormError] = useState(false);
  useEffect(() => {
    setPageLoading(true);
    if (singleProduct.name) {
      setPageContent({
        name: singleProduct.name,
        brand: singleProduct.brand,
        depth: singleProduct.depth,
        description: singleProduct.description,
        girth: singleProduct.girth,
        height: singleProduct.height,
        inventory_name: singleProduct.inventoryName,
        regular_price: singleProduct.regularPrice,
        shipping_charges: singleProduct.shippingCharges,
        stock_quantity: singleProduct.stockQuantity,
        total_unit_weight: singleProduct.totalUnitWeight,
        unit_weight: singleProduct.unitWeight,
        width: singleProduct.width,
        productAttributes: [...singleProduct.attributes],
        productMaterial: [...singleProduct.material],
        priceTier: [...singleProduct.priceTier],
        images: [...singleProduct.images],
        dimensionUnit: singleProduct.dimensionUnit,
        weightUnit: singleProduct.weightUnit,
        investmentStory: singleProduct.investmentStory || "",
        environmentStory: singleProduct.environmentStory || "",
        productStory: singleProduct.productStory || "",
      });
      setProductDescription({
        editorState: EditorState.createWithContent(
          convertFromRaw({
            entityMap: {},
            blocks: singleProduct?.specification?.blocks,
          })
        ),
      });
      if (singleProduct.category[0]) {
        const mainCategory = singleProduct?.mainCategory?._id;
        const subCategory = singleProduct?.subCategory?._id;
        const category = singleProduct.category.map((item) => item._id);
        setSelectedProductCategories(category);
        setSelectedMainCategory(mainCategory);
        setSelectedSubCategory(subCategory);
        dispatch(getProductSubCategories(mainCategory));
      }
    }
    setPageLoading(true);
  }, [singleProduct]);
  const [productAttributes, setProductAttributes] = useState({
    index: -1,
    variants: [],
  });
  const [productVariations, setProductVariations] = useState({
    index: -1,
  });
  const [productMaterial, setProductMaterial] = useState({
    index: -1,
  });
  const [productTrierPrice, setProductTrierPrice] = useState({
    index: -1,
  });
  const [selectedProductCategories, setSelectedProductCategories] = useState(
    []
  );
  const [selectedMainCategory, setSelectedMainCategory] = useState(0);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(0);

  useEffect(() => {
    if (singleProduct?.name) {
      if (singleProduct?.category[0]) {
        const subCategory = singleProduct.subCategory?._id;
        setSelectedSubCategory(subCategory);
        dispatch(getProductSubSubCategories(subCategory));
      }
    }
  }, [selectedMainCategory]);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const handleAttributesChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProductAttributes({
      ...productAttributes,
      [name]: value,
    });
  };
  const handleVariationsChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProductVariations({
      ...productVariations,
      [name]: value,
    });
  };
  const handleTierPriceChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProductTrierPrice({
      ...productTrierPrice,
      [name]: value,
    });
  };
  const handleMaterialChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setProductMaterial({
      ...productMaterial,
      [name]: value,
    });
  };
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "physical",
      })
    );
    dispatch(getCategories());
    setPageLoading(false);
  }, []);
  const handleCheckboxChanges = (category) => {
    const categories = [...selectedProductCategories];
    if (categories.includes(category._id)) {
      setSelectedProductCategories(
        categories.filter((item) => item !== category._id)
      );
    } else {
      setSelectedProductCategories([
        ...selectedProductCategories,
        category._id,
      ]);
    }
  };
  const save = () => {
    const specification = convertToRaw(
      productDescription.editorState.getCurrentContent()
    );
    setProductDescriptionData(specification?.blocks[0]?.text);

    const {
      name,
      brand,
      depth,
      description,
      dimensionUnit,
      girth,
      height,
      images,
      inventory_name,
      priceTier,
      productAttributes,
      productMaterial,
      regular_price,
      shipping_charges,
      stock_quantity,
      total_unit_weight,
      unit_weight,
      weightUnit,
      width,
      investmentStory,
      environmentStory,
      productStory,
    } = pageContent;
    if (
      !name ||
      !brand ||
      !images.length ||
      !depth ||
      !description ||
      !dimensionUnit ||
      !girth ||
      !priceTier.length ||
      !productAttributes.length ||
      !productMaterial.length ||
      !shipping_charges ||
      !stock_quantity ||
      !total_unit_weight ||
      !weightUnit ||
      !width ||
      !inventory_name ||
      !investmentStory ||
      !environmentStory ||
      !productStory
    ) {
      setFormError(true);
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Kindly fill the required fields "
        }`
      );
      return;
    }
    setFormError(false);
    const data = {
      id: productId,
      name,
      images,
      specification,
      brand,
      investmentStory,
      environmentStory,
      productStory,
      attributes: productAttributes,
      regularPrice: regular_price,
      width,
      depth,
      height,
      girth,
      inventoryName: inventory_name,
      unitWeight: unit_weight,
      description,
      totalUnitWeight: total_unit_weight,
      shippingCharges: shipping_charges,
      stockQuantity: stock_quantity,
      dimensionUnit,
      weightUnit,
      material: productMaterial,
      priceTier,
      dynamicPrice: {
        basePrice: regular_price,
        currentPrice: regular_price,
      },
      productType: "physical",
    };

    // check if any of selected category, sub category or sub sub category has values add it to the product data
    if (selectedProductCategories.length > 0) {
      data.category = selectedProductCategories; // sub sub category
    }
    if (selectedMainCategory) {
      data.mainCategory = selectedMainCategory; // main category
    }
    if (selectedSubCategory) {
      data.subCategory = selectedSubCategory; // sub category
    }

    dispatch(updateProduct(data)).then(() => {
      history.push("/seller/products");
    });
  };
  const addAttribute = () => {
    setProductAttributes({
      index: -1,
      name: "",
      value: "",
      variants: [],
      modalType: "attributes",
      attribute_title: "Add Attribute",
    });
    attributesModal.current.show();
  };
  const addMaterial = () => {
    setProductMaterial({
      index: -1,
      name: "",
      cost: "",
      description: "",
      modalType: "material",
      attribute_title: "Add Material",
    });
    materialModal.current.show();
  };
  const addVariant = () => {
    setProductVariations({
      index: -1,
      name: "",
      value: "",
      modalType: "variants",
      attribute_title: "Add Variant",
    });
    variantsModal.current.show();
  };
  const uploadImage = async (blobs) => {
    for (let i = 0; i < blobs.length; i++) {
      let newImages = [...pageContent.images];
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        newImages = [...newImages, imageUrl];
        setPageContent({
          ...pageContent,
          images: newImages,
        });
      } catch (error) {
        console.log("e", error);
        toast.error("Error in uploading image! Please try again");
      }
    }
  };
  const uploadProductImages = (event) => {
    uploadImage(event.target.files);
  };
  const editAttribute = (item, index) => {
    setProductAttributes({
      index: index,
      name: item.name,
      value: item.value,
      variants: item.variants,
      modalType: "attributes",
      attribute_title: "Edit Attribute",
    });
    attributesModal.current.show();
  };
  const editMaterial = (item, index) => {
    setProductMaterial({
      index: index,
      name: item.name,
      cost: item.cost,
      description: item.description,
      modalType: "material",
      attribute_title: "Edit Material",
    });
    materialModal.current.show();
  };
  const attributeSave = () => {
    if (productAttributes.index !== -1) {
      const allAttributes = [...pageContent.productAttributes];
      const attributes = { ...productAttributes };
      allAttributes[productAttributes.index] = attributes;
      setPageContent({
        ...pageContent,
        productAttributes: allAttributes,
      });
    } else {
      const attributes = { ...productAttributes };
      setPageContent({
        ...pageContent,
        productAttributes: [...pageContent.productAttributes, attributes],
      });
    }
    setProductAttributes({
      index: -1,
      variants: [],
      name: "",
      value: "",
    });
    attributesModal.current.hide();
  };
  const variantSave = () => {
    const { name, value } = productVariations;
    const allAttribute = [...pageContent.productAttributes];
    let attribute = pageContent.productAttributes.find(
      (item) => item.name == name
    );
    const attributeIndex = pageContent.productAttributes.findIndex(
      (item) => item.name == name
    );
    const variants = [...attribute.variants, value];
    attribute.variants = variants;
    allAttribute[attributeIndex] = attribute;
    setPageContent({
      ...pageContent,
      productAttributes: allAttribute,
    });
    variantsModal.current.hide();
  };
  const materialSave = () => {
    const { name, cost, description, index } = productMaterial;
    if (index !== -1) {
      let materials = [...pageContent.productMaterial];
      materials[index] = { name, cost, description };
      setPageContent({
        ...pageContent,
        productMaterial: materials,
      });
    } else {
      setPageContent({
        ...pageContent,
        productMaterial: [
          ...pageContent.productMaterial,
          { name, cost, description },
        ],
      });
    }
    setProductMaterial({
      index: -1,
      name: "",
      cost: "",
      description: "",
      modalType: "material",
      attribute_title: "Add Material",
    });
    materialModal.current.hide();
  };
  const variantTierPrice = () => {
    setPageContent({
      ...pageContent,
      priceTier: [...pageContent.priceTier, productTrierPrice],
    });
    tierPriceModal.current.hide();
  };
  const deleteAttibute = (item, index) => {
    const allAttributes = [...pageContent.productAttributes];
    allAttributes.splice(index, 1);
    setPageContent({
      ...pageContent,
      productAttributes: allAttributes,
    });
  };
  const deleteMaterial = (item, index) => {
    const allMaterial = [...pageContent.productMaterial];
    allMaterial.splice(index, 1);
    setPageContent({
      ...pageContent,
      productMaterial: allMaterial,
    });
  };

  const handleTierQuantity = (index, evt) => {
    if (parseInt(evt.target.value) >= 1) {
      var { priceTier } = pageContent;
      priceTier[index].quantity = parseInt(evt.target.value);
      setPageContent({
        ...pageContent,
        priceTier,
      });
    }
  };

  const handleTierDiscount = (index, evt) => {
    if (parseFloat(evt.target.value) >= 1) {
      var { priceTier } = pageContent;
      priceTier[index].discount = parseInt(evt.target.value);
      setPageContent({
        ...pageContent,
        priceTier,
      });
    }
  };

  const addTier = () => {
    setProductTrierPrice({
      index: -1,
      quantity: 0,
      discount: 0,
      modalType: "tierPrice",
      attribute_title: "Add Tier Price",
    });
    tierPriceModal.current.show();
  };

  const deleteTierRow = (index) => {
    var { priceTier } = pageContent;
    priceTier.splice(index, 1);
    setPageContent({
      ...pageContent,
      priceTier,
    });
  };
  const imageClick = (image) => {
    let newImages = _.cloneDeep(pageContent.images);
    newImages = newImages.filter((item) => item !== image);
    setPageContent({
      ...pageContent,
      images: newImages,
    });
  };
  const changeProductDescription = (editorState) => {
    setProductDescription({ editorState });
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center", margin: "210px 0" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : (
        <div className="animated fadeIn">
          <ToastContainer autoClose={20000} />
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={attributesModal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>{productAttributes.attribute_title}</h4>
                </b>
              </p>
            }
          >
            <div hidden={productAttributes.modalType !== "attributes"}>
              <FormGroup>
                <div className="controls">
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder="Attribute Name"
                      name="name"
                      value={productAttributes.name}
                      onChange={handleAttributesChange}
                      size="16"
                      type="text"
                    />
                  </InputGroup>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <Input
                    placeholder="Attribute Value"
                    name="value"
                    value={productAttributes.value}
                    onChange={handleAttributesChange}
                    size="16"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Label>
                    <strong style={{ color: "red" }}>*</strong>Use coma's for
                    multiple values.(e.g. red,blue,white etc)
                  </Label>
                </Row>
              </FormGroup>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={
                    !(productAttributes.name && productAttributes.value)
                  }
                  type="submit"
                  onClick={attributeSave}
                  size="md"
                  color="warning"
                >
                  {" "}
                  <strong>Save </strong>
                </Button>
                <Col xs="12" sm="1"></Col>
                <Button
                  type="submit"
                  size="md"
                  onClick={() => attributesModal.current.hide()}
                  color="danger"
                >
                  {" "}
                  <strong>
                    {digitalProductTranslation?.cancel
                      ? digitalProductTranslation?.cancel
                      : "Cancel"}
                  </strong>{" "}
                </Button>
              </Row>
            </div>
          </SkyLight>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={tierPriceModal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>{productTrierPrice.attribute_title}</h4>
                </b>
              </p>
            }
          >
            <div hidden={productTrierPrice.modalType !== "tierPrice"}>
              <FormGroup>
                <div className="controls">
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder="quantity"
                      name="quantity"
                      value={productTrierPrice.quantity}
                      onChange={handleTierPriceChange}
                      size="16"
                      type="number"
                    />
                  </InputGroup>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <Input
                    placeholder="discount"
                    name="discount"
                    value={productTrierPrice.discount}
                    onChange={handleTierPriceChange}
                    size="16"
                    type="number"
                  />
                </InputGroup>
              </FormGroup>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={
                    !(productTrierPrice.discount && productTrierPrice.quantity)
                  }
                  type="submit"
                  onClick={variantTierPrice}
                  size="md"
                  color="warning"
                >
                  <strong>Save </strong>
                </Button>
                <Col xs="12" sm="1"></Col>
                <Button
                  type="submit"
                  size="md"
                  onClick={() => attributesModal.current.hide()}
                  color="danger"
                >
                  <strong>
                    {digitalProductTranslation?.cancel
                      ? digitalProductTranslation?.cancel
                      : "Cancel"}
                  </strong>{" "}
                </Button>
              </Row>
            </div>
          </SkyLight>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={variantsModal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>{productVariations.attribute_title}</h4>
                </b>
              </p>
            }
          >
            <div hidden={productVariations.modalType !== "variants"}>
              <FormGroup>
                <div className="controls">
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      type="select"
                      name="name"
                      onChange={handleVariationsChange}
                    >
                      {pageContent.productAttributes.length > 0
                        ? pageContent.productAttributes.map((item, i) => (
                            <option value={item.name}> {item.name} </option>
                          ))
                        : "Attributes not found"}
                    </Input>
                  </InputGroup>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <Input
                    placeholder="value"
                    name="value"
                    value={productVariations.value}
                    onChange={handleVariationsChange}
                    size="16"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={
                    !(productVariations.name && productVariations.value)
                  }
                  type="submit"
                  onClick={variantSave}
                  size="md"
                  color="warning"
                >
                  {" "}
                  <strong>Save </strong>
                </Button>
                <Col xs="12" sm="1"></Col>
                <Button
                  type="submit"
                  size="md"
                  onClick={() => attributesModal.current.hide()}
                  color="danger"
                >
                  {" "}
                  <strong>
                    {digitalProductTranslation?.cancel
                      ? digitalProductTranslation?.cancel
                      : "Cancel"}
                  </strong>{" "}
                </Button>
              </Row>
            </div>
          </SkyLight>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={materialModal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>{productMaterial.attribute_title}</h4>
                </b>
              </p>
            }
          >
            <div hidden={productMaterial.modalType !== "material"}>
              <FormGroup>
                <div className="controls">
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder="name"
                      name="name"
                      value={productMaterial.name}
                      onChange={handleMaterialChange}
                      size="16"
                      type="tex"
                    />
                  </InputGroup>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="controls">
                  <InputGroup className="input-prepend">
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                    <Input
                      placeholder="cost"
                      name="cost"
                      value={productMaterial.cost}
                      onChange={handleMaterialChange}
                      size="16"
                      type="tex"
                    />
                  </InputGroup>
                </div>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-prepend">
                  <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  <Input
                    placeholder="description"
                    name="description"
                    value={productMaterial.value}
                    onChange={handleMaterialChange}
                    size="16"
                    type="textarea"
                  />
                </InputGroup>
              </FormGroup>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  disabled={!(productMaterial.name && productMaterial.cost)}
                  type="submit"
                  onClick={materialSave}
                  size="md"
                  color="warning"
                >
                  {" "}
                  <strong>Save </strong>
                </Button>
                <Col xs="12" sm="1"></Col>
                <Button
                  type="submit"
                  size="md"
                  onClick={() => attributesModal.current.hide()}
                  color="danger"
                >
                  {" "}
                  <strong>
                    {digitalProductTranslation?.cancel
                      ? digitalProductTranslation?.cancel
                      : "Cancel"}
                  </strong>{" "}
                </Button>
              </Row>
            </div>
          </SkyLight>
          <Row>
            <Col xs="12" sm="12">
              <Card>
                <CardHeader>
                  <b>
                    {addProductTranslation?.general
                      ? addProductTranslation?.general
                      : "General"}
                  </b>
                </CardHeader>
                <CardBody className="card-body">
                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.productName
                              ? addProductTranslation?.productName
                              : "Product Name"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body">
                          <FormGroup>
                            <div className="controls">
                              <InputGroup
                                className={`input-prepend ${
                                  !pageContent?.name &&
                                  formError &&
                                  "form-error"
                                }`}
                              >
                                <InputGroupAddon></InputGroupAddon>
                                <Input
                                  onChange={handleInputChange}
                                  name="name"
                                  size="16"
                                  type="text"
                                  placeholder="Product name"
                                  className="errorMessages"
                                  value={pageContent.name}
                                  required
                                />
                              </InputGroup>
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.productSpecification
                              ? addProductTranslation?.productSpecification
                              : "Product Specification"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body">
                          <FormGroup>
                            <div className="controls">
                              <Editor
                                toolbarClassName="toolbar-class"
                                placeholder="Product Description"
                                wrapperClassName="demo-wrapper"
                                editorClassName="editer-content"
                                editorStyle={{
                                  border: `${
                                    formError && !productDescriptionData
                                      ? "2px solid red"
                                      : ""
                                  }`,
                                }}
                                editorState={productDescription.editorState}
                                onEditorStateChange={changeProductDescription}
                              />
                              {/* <p className="onerror"> {state.prod_description}</p> */}
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.brand
                              ? addProductTranslation?.brand
                              : "Brand"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body">
                          <FormGroup>
                            <div className="controls">
                              <InputGroup
                                className={`input-prepend ${
                                  !pageContent?.brand &&
                                  formError &&
                                  "form-error"
                                }`}
                              >
                                <Input
                                  id="appendedPrependedInput"
                                  name="brand"
                                  placeholder="Brand"
                                  onChange={handleInputChange}
                                  size="16"
                                  type="text"
                                  value={pageContent.brand}
                                  required
                                />
                              </InputGroup>
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {pageContent?.investmentStory !== undefined &&
                    pageContent?.environmentStory !== undefined &&
                    pageContent?.productStory !== undefined && (
                      <Row>
                        <Col xs="12" sm="12">
                          <Card>
                            <CardHeader>
                              <b>Product Story</b>
                            </CardHeader>
                            <CardBody className="card-body">
                              <FormGroup>
                                <div className="  my-2 ">
                                  <Label for="InvestmentText">
                                    Investment on product{" "}
                                    <span
                                      style={{
                                        display: "block",
                                        font: "small-caption",
                                      }}
                                    >
                                      (i.e can be the cost of production, cost
                                      of materials, cost of labor etc)
                                    </span>
                                  </Label>
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.investment &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      id="InvestmentText"
                                      name="investmentStory"
                                      placeholder="Investment on product"
                                      onChange={handleInputChange}
                                      maxlength="30"
                                      size="16"
                                      type="number"
                                      value={pageContent?.investmentStory}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                                <div className="controls my-2 ">
                                  <Label for="environmentText">
                                    Impact on environment
                                  </Label>
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.environment &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      className="text-eara-product-story"
                                      id="environmentText"
                                      name="environmentStory"
                                      placeholder="Impact on environment"
                                      onChange={handleInputChange}
                                      maxlength="30"
                                      size="16"
                                      type="textarea"
                                      value={pageContent.environmentStory}
                                      required
                                    />
                                  </InputGroup>
                                </div>

                                <div className="controls my-2">
                                  <Label for="productStoryText">
                                    Brief Product Story
                                  </Label>
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.productStory &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      className="text-eara-product-story"
                                      id="productStoryText"
                                      name="productStory"
                                      placeholder=" Brief Product Story"
                                      onChange={handleInputChange}
                                      maxlength="30"
                                      size="16"
                                      type="textarea"
                                      value={pageContent.productStory}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.image
                              ? addProductTranslation?.image
                              : "Images"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Row action="" method="post">
                            {pageContent.images.length > 0
                              ? pageContent.images.map((item, index) => (
                                  <Col xs="12" lg="3">
                                    <div class="container">
                                      <img
                                        style={{ width: "100%" }}
                                        src={item}
                                        alt="Avatar"
                                        class="image"
                                      />
                                      <div
                                        onClick={() => imageClick(item)}
                                        class=""
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div class="close"></div>
                                      </div>
                                    </div>
                                  </Col>
                                ))
                              : ""}
                            <Col xs="12" lg="12">
                              <div className="file-uploader">
                                <input
                                  type="file"
                                  multiple={true}
                                  onChange={uploadProductImages}
                                  ref={fileInput}
                                  hidden
                                />
                                <button
                                  onClick={(e) =>
                                    fileInput.current &&
                                    fileInput.current.click()
                                  }
                                  className={`btn btn-primary ${
                                    !pageContent?.images.length > 0 &&
                                    formError &&
                                    "form-error"
                                  }`}
                                >
                                  {addProductTranslation?.image
                                    ? addProductTranslation?.image
                                    : "Upload Images"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.chooseCategory
                              ? addProductTranslation?.chooseCategory
                              : "Choose Category"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body category-body">
                          <FormGroup>
                            <div className="d-flex flex-row justify-content-between flex-wrap">
                              <div className="d-flex flex-column m-2 category-dropdowns">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span>
                                    {addProductTranslation?.main
                                      ? addProductTranslation?.main
                                      : "Main Category"}
                                  </span>
                                  <br />
                                </div>
                                {productCategories?.length ? (
                                  <select
                                    onChange={(e) => {
                                      setSelectedMainCategory(e.target.value);
                                      dispatch(
                                        getProductSubCategories(e.target.value)
                                      );
                                    }}
                                    value={selectedMainCategory}
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    {productCategories.map((item, index) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  `${
                                    addProductTranslation?.main
                                      ? addProductTranslation?.main
                                      : "No Category Found"
                                  }`
                                )}
                              </div>
                              <div className="d-flex flex-column m-2 category-dropdowns">
                                <div
                                  style={{
                                    display: "flex",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {productSubCategories.length > 0
                                    ? "Sub Category"
                                    : null}
                                </div>
                                {productSubCategories.length > 0 ? (
                                  <select
                                    value={selectedSubCategory}
                                    onChange={(e) => {
                                      setSelectedSubCategory(e.target.value);
                                      dispatch(
                                        getProductSubSubCategories(
                                          e.target.value
                                        )
                                      );
                                    }}
                                    style={{
                                      border: "1px solid #ccc",
                                      borderRadius: "5px",
                                      padding: "5px",
                                    }}
                                  >
                                    {productSubCategories.map((item, index) => (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                ) : (
                                  `${
                                    addProductTranslation?.main
                                      ? addProductTranslation?.main
                                      : "No Category Found"
                                  }`
                                )}
                              </div>
                              <div className="d-flex flex-column m-2 category-dropdowns">
                                <div
                                  style={{
                                    display: "flex",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {productSubSubCategories.length > 0
                                    ? "Sub Sub Category"
                                    : null}
                                </div>
                                {productSubSubCategories.length > 0
                                  ? productSubSubCategories.map(
                                      (item, index) => (
                                        <Col xs="12" sm="12" className="p-0">
                                          <input
                                            type="checkbox"
                                            value={item.name}
                                            checked={selectedProductCategories.includes(
                                              item._id
                                            )}
                                            onChange={(e) =>
                                              handleCheckboxChanges(item)
                                            }
                                          />
                                          <span className="ml-1">
                                            {item.name}
                                          </span>
                                        </Col>
                                      )
                                    )
                                  : `${
                                      addProductTranslation?.main
                                        ? addProductTranslation?.main
                                        : "No Category Found"
                                    }`}
                              </div>
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" sm="12">
                      <Card>
                        <CardHeader>
                          <b>
                            {addProductTranslation?.detail
                              ? addProductTranslation?.detail
                              : "Product Details"}
                          </b>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Form
                            action=""
                            method="post"
                            className="form-horizontal"
                          >
                            <Row>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label>
                                    {addProductTranslation?.basePrice
                                      ? addProductTranslation?.basePrice
                                      : "Base Price"}
                                  </Label>
                                  <div className="controls">
                                    <InputGroup
                                      className={`input-prepend ${
                                        !pageContent?.regular_price &&
                                        formError &&
                                        "form-error"
                                      }`}
                                    >
                                      <Input
                                        placeholder="Regular Price"
                                        name="regular_price"
                                        onChange={handleInputChange}
                                        id="appendedPrependedInput"
                                        size="16"
                                        type="text"
                                        value={pageContent.regular_price}
                                        required
                                      />
                                    </InputGroup>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label>
                                    {addProductTranslation?.width
                                      ? addProductTranslation?.width
                                      : "Width"}
                                  </Label>
                                  <div className="controls">
                                    <InputGroup
                                      className={`input-prepend ${
                                        !pageContent?.width &&
                                        formError &&
                                        "form-error"
                                      }`}
                                    >
                                      <Input
                                        placeholder={
                                          addProductTranslation?.width
                                            ? addProductTranslation?.width
                                            : "Width"
                                        }
                                        name="width"
                                        onChange={handleInputChange}
                                        id="appendedPrependedInput"
                                        size="16"
                                        type="text"
                                        value={pageContent.width}
                                        required
                                      />
                                    </InputGroup>
                                  </div>
                                </FormGroup>
                                {/* <p className="onerror"> {state.formErrors.width}</p> */}
                              </Col>

                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label>
                                    {addProductTranslation?.depth
                                      ? addProductTranslation?.depth
                                      : "Depth"}
                                  </Label>
                                  <div className="controls">
                                    <InputGroup
                                      className={`input-prepend ${
                                        !pageContent?.depth &&
                                        formError &&
                                        "form-error"
                                      }`}
                                    >
                                      <Input
                                        placeholder={
                                          addProductTranslation?.depth
                                            ? addProductTranslation?.depth
                                            : "Depth"
                                        }
                                        name="depth"
                                        onChange={handleInputChange}
                                        id="appendedPrependedInput"
                                        size="16"
                                        type="text"
                                        value={pageContent.depth}
                                        required
                                      />
                                    </InputGroup>
                                  </div>
                                </FormGroup>
                                {/* <p className="onerror"> {state.formErrors.depth}</p> */}
                              </Col>

                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label>
                                    {addProductTranslation?.heigth
                                      ? addProductTranslation?.heigth
                                      : "Height"}
                                  </Label>
                                  <div className="controls">
                                    <InputGroup
                                      className={`input-prepend ${
                                        !pageContent?.height &&
                                        formError &&
                                        "form-error"
                                      }`}
                                    >
                                      <Input
                                        placeholder={
                                          addProductTranslation?.heigth
                                            ? addProductTranslation?.heigth
                                            : "Height"
                                        }
                                        name="height"
                                        onChange={handleInputChange}
                                        id="appendedPrependedInput"
                                        size="16"
                                        type="text"
                                        value={pageContent.height}
                                        required
                                      />
                                    </InputGroup>
                                  </div>
                                </FormGroup>
                                {/* <p className="onerror">
                                {" "}
                                {state.formErrors.height}
                              </p> */}
                              </Col>
                              <Col xs="12" sm="4">
                                <FormGroup>
                                  <Label>
                                    {addProductTranslation?.girth
                                      ? addProductTranslation?.girth
                                      : "Girth"}
                                  </Label>
                                  <div className="controls">
                                    <InputGroup
                                      className={`input-prepend ${
                                        !pageContent?.girth &&
                                        formError &&
                                        "form-error"
                                      }`}
                                    >
                                      <Input
                                        placeholder="Girth"
                                        name="girth"
                                        onChange={handleInputChange}
                                        id="appendedPrependedInput"
                                        size="16"
                                        type="text"
                                        value={pageContent.girth}
                                        required
                                      />
                                    </InputGroup>
                                  </div>
                                </FormGroup>
                                {/* <p className="onerror"> {state.formErrors.girth}</p> */}
                              </Col>
                            </Row>
                            <FormGroup>
                              <Row>
                                <Col xs="12" sm="4">
                                  <Card>
                                    <CardHeader>
                                      {addProductTranslation?.dimension
                                        ? addProductTranslation?.dimension
                                        : "Dimension Units"}
                                    </CardHeader>
                                    <CardBody className="card-body">
                                      <Form
                                        action=""
                                        method="post"
                                        className="form-horizontal"
                                      >
                                        <FormGroup>
                                          {dimension_units.map(
                                            (item, index) => (
                                              <Row
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <Col xs="12" sm="1">
                                                  <InputGroup className="input-prepend">
                                                    <Input
                                                      className="form-check-input"
                                                      type="radio"
                                                      id={item}
                                                      name="dimensionUnit"
                                                      checked={
                                                        pageContent.dimensionUnit ===
                                                        item
                                                      }
                                                      value={item}
                                                      onClick={
                                                        handleInputChange
                                                      }
                                                    />
                                                  </InputGroup>
                                                </Col>
                                                <Col xs="12" sm="8">
                                                  <Label htmlFor="appendedPrependedInput">
                                                    {item}
                                                  </Label>
                                                </Col>
                                              </Row>
                                            )
                                          )}
                                        </FormGroup>
                                      </Form>
                                    </CardBody>
                                  </Card>
                                </Col>

                                <Col xs="12" sm="5">
                                  <Card>
                                    <CardHeader>
                                      {addProductTranslation?.weight
                                        ? addProductTranslation?.weight
                                        : "Weight Units"}
                                    </CardHeader>
                                    <CardBody className="card-body">
                                      <Form
                                        action=""
                                        method="post"
                                        className="form-horizontal"
                                      >
                                        <FormGroup>
                                          {weight_units.map((item, index) => (
                                            <Row
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <Col xs="12" sm="1">
                                                <InputGroup className="input-prepend">
                                                  <Input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id={item}
                                                    name="weightUnit"
                                                    checked={
                                                      pageContent.weightUnit ===
                                                      item
                                                    }
                                                    value={item}
                                                    onClick={handleInputChange}
                                                  />
                                                </InputGroup>
                                              </Col>
                                              <Col xs="12" sm="8">
                                                <Label htmlFor="appendedPrependedInput">
                                                  {" "}
                                                  {item}
                                                </Label>
                                              </Col>
                                            </Row>
                                          ))}
                                        </FormGroup>
                                      </Form>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="12" sm="11">
                              <b>
                                {otherProductTranslation?.inventory
                                  ? otherProductTranslation?.inventory
                                  : "Inventory"}
                              </b>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Row>
                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.name
                                    ? otherProductTranslation?.name
                                    : "Name"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.inventory_name &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.name
                                          ? otherProductTranslation?.name
                                          : "Name"
                                      }
                                      name="inventory_name"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.inventory_name}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              {/* <p className="onerror">
                              {" "}
                              {state.formErrors.inventory_name}
                            </p> */}
                            </Col>
                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.stock
                                    ? otherProductTranslation?.stock
                                    : "Stock Quantity"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.stock_quantity &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.stock
                                          ? otherProductTranslation?.stock
                                          : "Stock Quantity"
                                      }
                                      name="stock_quantity"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.stock_quantity}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              {/* <p className="onerror">
                              {" "}
                              {state.formErrors.stock_quantity}
                            </p> */}
                            </Col>
                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.unitWeight
                                    ? otherProductTranslation?.unitWeight
                                    : "Unit Weight"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.unit_weight &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.unitWeight
                                          ? otherProductTranslation?.unitWeight
                                          : "Unit Weight"
                                      }
                                      name="unit_weight"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.unit_weight}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              {/* <p className="onerror">
                              {" "}
                              {state.formErrors.unit_weight}
                            </p> */}
                            </Col>

                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.description
                                    ? otherProductTranslation?.description
                                    : "Description"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.description &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.description
                                          ? otherProductTranslation?.description
                                          : "Description"
                                      }
                                      name="description"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.description}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                              {/* <p className="onerror">
                              {" "}
                              {state.formErrors.description}
                            </p> */}
                            </Col>

                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.shippingCharges
                                    ? otherProductTranslation?.shippingCharges
                                    : "Shipping Charges"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.shipping_charges &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.shippingCharges
                                          ? otherProductTranslation?.shippingCharges
                                          : "Shipping Charges"
                                      }
                                      name="shipping_charges"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.shipping_charges}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </Col>
                            <Col xs="12" sm="4">
                              <FormGroup>
                                <Label>
                                  {otherProductTranslation?.totalUnits
                                    ? otherProductTranslation?.totalUnits
                                    : "Total Units Weight"}
                                </Label>
                                <div className="controls">
                                  <InputGroup
                                    className={`input-prepend ${
                                      !pageContent?.total_unit_weight &&
                                      formError &&
                                      "form-error"
                                    }`}
                                  >
                                    <Input
                                      placeholder={
                                        otherProductTranslation?.totalUnits
                                          ? otherProductTranslation?.totalUnits
                                          : "Total Units Weight"
                                      }
                                      name="total_unit_weight"
                                      onChange={handleInputChange}
                                      id="appendedPrependedInput"
                                      size="16"
                                      type="text"
                                      value={pageContent.total_unit_weight}
                                      required
                                    />
                                  </InputGroup>
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="12" sm="11">
                              <b>
                                {otherProductTranslation?.productHeading
                                  ? otherProductTranslation?.productHeading
                                  : "Product Material Info"}
                              </b>
                              <p style={{ fontSize: "14px" }}>
                                {otherProductTranslation?.productLine
                                  ? otherProductTranslation?.productLine
                                  : "Kindly add all detailed material/transportation/men-power with price used in production"}
                              </p>
                            </Col>
                            <Col xs="12" sm="1">
                              <Button
                                style={{
                                  border: `${
                                    pageContent.productMaterial.length === 0 &&
                                    formError
                                      ? "2px solid red"
                                      : ""
                                  }`,
                                }}
                                type="submit"
                                onClick={addMaterial}
                                size="md"
                                color="warning"
                              >
                                {otherProductTranslation?.add
                                  ? otherProductTranslation?.add
                                  : "Add"}
                              </Button>
                              <span> </span>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Table hover bordered striped responsive size="sm">
                            <thead>
                              <tr className="header-color">
                                <th>
                                  {otherProductTranslation?.name
                                    ? otherProductTranslation?.name
                                    : "Name"}
                                </th>
                                <th>
                                  {otherProductTranslation?.price
                                    ? otherProductTranslation?.price
                                    : "Price"}
                                </th>
                                <th>
                                  {digitalProductTranslation?.description
                                    ? digitalProductTranslation?.description
                                    : "Description"}
                                </th>
                                <th>
                                  {otherProductTranslation?.action
                                    ? otherProductTranslation?.action
                                    : "Action"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pageContent.productMaterial.length > 0
                                ? pageContent.productMaterial.map(
                                    (item, index) => (
                                      <tr>
                                        <td>{item.name}</td>
                                        <td>{item.price}</td>
                                        <td>{item.description}</td>
                                        <td align="center">
                                          <i
                                            className="fa fa-edit fa-lg mt-4 mx-1"
                                            onClick={(e) =>
                                              editMaterial(item, index)
                                            }
                                          ></i>
                                          <i
                                            onClick={(e) =>
                                              deleteMaterial(item, index)
                                            }
                                            className="fa fa-trash fa-lg mt-4"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : ""}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="12" sm="11">
                              <b>
                                {otherProductTranslation?.productAttribute
                                  ? otherProductTranslation?.productAttribute
                                  : "Product Attribute"}
                              </b>
                            </Col>
                            <Col xs="12" sm="1">
                              <Button
                                style={{
                                  border: `${
                                    pageContent.productAttributes.length ===
                                      0 && formError
                                      ? "2px solid red"
                                      : ""
                                  }`,
                                }}
                                type="submit"
                                onClick={addAttribute}
                                size="md"
                                color="warning"
                              >
                                {" "}
                                {otherProductTranslation?.add
                                  ? otherProductTranslation?.add
                                  : "Add"}{" "}
                              </Button>{" "}
                              <span> </span>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Table hover bordered striped responsive size="sm">
                            <thead>
                              <tr className="header-color">
                                <th>
                                  {otherProductTranslation?.name
                                    ? otherProductTranslation?.name
                                    : "Attribute Name"}
                                </th>
                                <th>
                                  {digitalProductTranslation?.description
                                    ? digitalProductTranslation?.description
                                    : "Attribute Description"}
                                </th>
                                <th>
                                  {otherProductTranslation?.action
                                    ? otherProductTranslation?.action
                                    : "Action"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pageContent.productAttributes.length > 0
                                ? pageContent.productAttributes.map(
                                    (item, index) => (
                                      <tr>
                                        <td>{item.name}</td>
                                        <td>{item.value}</td>
                                        <td align="center">
                                          <i
                                            className="fa fa-edit fa-lg mt-4 mx-1"
                                            onClick={(e) =>
                                              editAttribute(item, index)
                                            }
                                          ></i>
                                          <i
                                            onClick={(e) =>
                                              deleteAttibute(item, index)
                                            }
                                            className="fa fa-trash fa-lg mt-4"
                                          ></i>
                                        </td>
                                      </tr>
                                    )
                                  )
                                : ""}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="12" sm="11">
                              <b>
                                {otherProductTranslation?.varients
                                  ? otherProductTranslation?.varients
                                  : "Variants"}
                              </b>
                            </Col>
                            <Col xs="12" sm="1">
                              <Button
                                style={{
                                  border: `${
                                    pageContent.productAttributes.length ===
                                      0 && formError
                                      ? "2px solid red"
                                      : ""
                                  }`,
                                }}
                                type="submit"
                                onClick={addVariant}
                                size="md"
                                color="warning"
                              >
                                {otherProductTranslation?.add
                                  ? otherProductTranslation?.add
                                  : "Add"}
                              </Button>
                              <span> </span>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Table hover bordered striped responsive size="sm">
                            <thead>
                              <tr className="header-color">
                                <th>
                                  {otherProductTranslation?.varients
                                    ? otherProductTranslation?.varients
                                    : "Variants"}
                                </th>
                                <th>
                                  {productDetailTranslation?.suggestValue
                                    ? productDetailTranslation?.suggestValue
                                    : "Value"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pageContent.productAttributes.length > 0
                                ? pageContent.productAttributes.map(
                                    (item, index) => (
                                      <>
                                        {item.variants.map((k, i) => (
                                          <tr>
                                            <td>{item.name}</td>
                                            <td>{k}</td>
                                          </tr>
                                        ))}
                                      </>
                                    )
                                  )
                                : ""}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="12">
                      <Card>
                        <CardHeader>
                          <Row>
                            <Col xs="12" sm="11">
                              <b>
                                {otherProductTranslation?.tierPrice
                                  ? otherProductTranslation?.tierPrice
                                  : "Tier Price"}
                              </b>
                            </Col>
                            <Col xs="12" sm="1">
                              <Button
                                style={{
                                  border: `${
                                    pageContent.priceTier.length === 0 &&
                                    formError
                                      ? "2px solid red"
                                      : ""
                                  }`,
                                }}
                                type="submit"
                                onClick={addTier}
                                size="md"
                                color="warning"
                              >
                                {otherProductTranslation?.add
                                  ? otherProductTranslation?.add
                                  : "Add"}
                              </Button>
                              <span> </span>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="card-body">
                          <Table hover bordered striped responsive size="sm">
                            <thead>
                              <tr className="header-color">
                                <th>
                                  {otherProductTranslation?.id
                                    ? otherProductTranslation?.id
                                    : "id"}
                                </th>
                                <th>
                                  {otherProductTranslation?.quantity
                                    ? otherProductTranslation?.quantity
                                    : "Quantity"}
                                </th>
                                <th>
                                  {otherProductTranslation?.discount
                                    ? otherProductTranslation?.discount
                                    : "Discount"}
                                </th>
                                <th>
                                  {otherProductTranslation?.action
                                    ? otherProductTranslation?.action
                                    : "Action"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {pageContent.priceTier.length > 0
                                ? pageContent.priceTier.map((item, index) => (
                                    <tr>
                                      <td style={{ verticalAlign: "middle" }}>
                                        {index + 1}
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <div className="d-md-flex align-items-center justify-content-between">
                                          <div>
                                            <Input
                                              id="appendedPrependedInput"
                                              name="brand"
                                              onChange={(event) =>
                                                handleTierQuantity(index, event)
                                              }
                                              style={{ width: "200px" }}
                                              type="number"
                                              value={item.quantity}
                                              required
                                            />
                                          </div>
                                          <div className="mx-5">and above</div>
                                        </div>
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <div className="d-md-flex align-items-center justify-content-between">
                                          <div>
                                            <Input
                                              id="appendedPrependedInput"
                                              name="brand"
                                              onChange={(event) =>
                                                handleTierDiscount(index, event)
                                              }
                                              style={{ width: "200px" }}
                                              type="number"
                                              value={item.discount}
                                              required
                                              max="100"
                                            />
                                          </div>
                                          <div className="mx-5">%</div>
                                        </div>
                                      </td>
                                      <td
                                        align="center"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <i
                                          onClick={(e) => deleteTierRow(index)}
                                          className="fa fa-trash fa-lg mt-4 mx-1"
                                        ></i>
                                      </td>
                                    </tr>
                                  ))
                                : ""}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="p-2">
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col xs="6" sm="2">
                      <Button
                        color="primary"
                        type="submit"
                        className="btn btn-sm"
                        onClick={save}
                        block
                      >
                        {digitalProductTranslation?.saveButton
                          ? digitalProductTranslation?.saveButton
                          : "Save"}
                      </Button>
                    </Col>
                    <Col xs="6" sm="2">
                      <Button
                        color="danger"
                        className="btn btn-sm"
                        onClick={(e) => history.push("/seller/products")}
                        block
                      >
                        {digitalProductTranslation?.cancel
                          ? digitalProductTranslation?.cancel
                          : "Cancel"}
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default AddProduct;
